import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import pdf from "../../static/pdfs/SDOS_2022_Academic_Advantage_Presentation.pdf"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const academicadvantage = () => {
  const img = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`

  const images = [
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007590/SDOS/DEV/aa-22-01.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007592/SDOS/DEV/aa-22-02.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007584/SDOS/DEV/aa-22-03.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007589/SDOS/DEV/aa-22-04.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007592/SDOS/DEV/aa-22-05.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007587/SDOS/DEV/aa-22-06.jpg",
    "https://res.cloudinary.com/nuvolum/image/upload/v1670007588/SDOS/DEV/aa-22-07.jpg"
  ]

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={"en"}>
        <SEO
          lang={"en"}
          title="Academic Advantage Healthcare Symposium for Students | The Oral Surgery & Dental Implant Specialists of San Diego"
          description="Academic Advantage Healthcare Symposium for Students."
        />

        <div className="edu-22-program">
          <div className="edu-22-header">
            <h1>Let's Talk About Your Future.</h1>
            <h2>Healthcare Symposium for Students</h2>
          </div>
          <div className="edu-22__hero">
            <div className="edu-22__hero--desktop">
              <img
                className="edu-22__hero-img"
                src={img("2022-edu-hero-image")}
                alt="program banner"
              />
            </div>
            <div className="edu-22__hero--mobile">
              <img
                className="edu-22__hero-img"
                src={img("2022-edu-mobile-hero-image")}
                alt="program banner"
              />
            </div>
          </div>

          <div className="edu-22__section about">
            <div className="edu-22__about-hero">
              <h3 className="mt-0">Here’s What You Learned:</h3>
              <ul>
                <li>
                  When and how to start planning for your healthcare career
                </li>
                <li>Tips on preparing for college and medical school</li>
                <li>The daily routine of a medical and dental professional</li>
                <li>What you can expect from a medical career</li>
                <li>What it’s like to be an entrepreneur and a doctor</li>
                <li>The many healthcare career paths you can take</li>
              </ul>

              <br />

              <p>
                It was our pleasure to host our Academic Advantage healthcare
                symposium in partnership with the{" "}
                <a
                  href="https://www.sandiegounified.org/departments/college_career_technical_education"
                  title="Learn More About College, Career & Technical Education"
                  target="_blank"
                >
                  College, Career & Technical Education
                </a>{" "}
                Department from the San Diego Unified School District at UC San
                Diego Park & Market in late November. Each of our speakers gave
                an overview of what it took for them to become the medical
                professionals they are today, while our college advisors gave
                practical advice for the next steps students should take,
                including college research and applications.
              </p>

              {/* <h5>
                <strong>
                  <Link to="/academicadvantage#edu-22-form">
                    Students who attended our Academic Advantage event can apply
                    for one of two $500 scholarships now through December 15,
                    2022.
                  </Link>
                </strong>
              </h5> */}
              <br />
              <p>
                The resources below are designed to help you continue on your
                path to becoming a healthcare professional. The entire
                presentation is also available below as a PDF.
              </p>

              <img
                src={img("2022-edu-second-image")}
                alt="edu doctor desktop"
              />
            </div>
          </div>

          <div className="edu-22__about-hero--mobile">
            <img
              src={img("2022-edu-mobile-second-image")}
              alt="edu doctor desktop"
            />
          </div>

          <div className="edu-22__section--color-gradient-container">
            <div className="edu-22__section--color-gradient">
              <h3>Featured Speakers</h3>
              <div className="edu-22__speakers-list">
                <p>
                  <strong>
                    <Link
                      to="/paul-koshgerian-dmd/"
                      title="Learn more about Paul Koshgerian, DMD, MD"
                    >
                      Paul Koshgerian, DMD, MD
                    </Link>
                  </strong>
                  <br />
                  Oral and Maxillofacial Surgeon
                  <br />
                  at{" "}
                  <Link
                    to="/"
                    title="Learn more about The Oral Surgery & Dental Implant Specialists of San Diego"
                  >
                    The Oral Surgery & Dental Implant Specialists of San Diego
                  </Link>
                </p>

                <p>
                  <strong>
                    <Link
                      to="/jonathan-shelton-dmd/"
                      title="Learn more about Jonathan Shelton, DMD, MD"
                    >
                      Jonathan Shelton, DMD, MD
                    </Link>
                  </strong>
                  <br />
                  Oral and Maxillofacial Surgeon
                  <br />
                  at{" "}
                  <Link
                    to="/"
                    title="Learn more about The Oral Surgery & Dental Implant Specialists of San Diego"
                  >
                    The Oral Surgery & Dental Implant Specialists of San Diego
                  </Link>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://www.sdsm.com/providers/"
                      title="Learn more about Shannon K. Cheffet, DO"
                      target="_blank"
                    >
                      Shannon K. Cheffet, DO
                    </a>
                  </strong>
                  <br />
                  <a
                    href="https://www.sdsm.com/"
                    title="Learn more about San Diego Sports Medicine & Family Health Center"
                    target="_blank"
                  >
                    San Diego Sports Medicine & Family Health Center
                  </a>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://healthy.kaiserpermanente.org/southern-california/physicians/brian-lee-5862778"
                      title="Learn more about Brian W. Lee, MD"
                      target="_blank"
                    >
                      Brian W. Lee, MD
                    </a>
                  </strong>
                  <br />
                  Pediatric Anesthesiologist
                  <br />
                  at{" "}
                  <a
                    href="https://healthy.kaiserpermanente.org/southern-california/front-door"
                    title="Learn more about Kaiser Permanente"
                    target="_blank"
                  >
                    Kaiser Permanente
                  </a>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://www.pointloma.edu/faculty/amy-vu-msm-pa-c"
                      title="Learn more about Amy Vu, MSM, ATC, PA-C"
                      target="_blank"
                    >
                      Amy Vu, MSM, ATC, PA-C
                    </a>
                  </strong>
                  <br />
                  Emergency Medicine Physician Assistant; Assistant Professor,
                  Director of Clinical Education; Physician Assistant, Education
                  Dept.
                  <br />
                  at{" "}
                  <a
                    href="https://www.pointloma.edu/"
                    title="Learn more about  Point Loma Nazarene University"
                    target="_blank"
                  >
                    Point Loma Nazarene University
                  </a>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://protocolsportsystems.com/about/"
                      title="Learn more about Kara Garcia, DPT, ATC"
                      target="_blank"
                    >
                      Kara Garcia, DPT, ATC
                    </a>
                  </strong>
                  <br />
                  Certified Athletic Trainer and Licensed Physical Therapist
                  <br />
                  at{" "}
                  <a
                    href="https://protocolsportsystems.com/"
                    title="Learn more about Pro To Col Sport Systems"
                    target="_blank"
                  >
                    Pro To Col Sport Systems
                  </a>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://sites.google.com/sandi.net/ccte/ccte-department"
                      title="Learn more about Alissa Tuter"
                      target="_blank"
                    >
                      Alissa Tuter
                    </a>
                  </strong>
                  <br />
                  Head Counselor in the Office of College, Career and Technical
                  Education
                  <br />
                  at{" "}
                  <a
                    href="https://www.sandiegounified.org/"
                    title="Learn more about San Diego Unified School District"
                    target="_blank"
                  >
                    San Diego Unified School District
                  </a>
                </p>

                <p>
                  <strong>
                    <a
                      href="https://sites.google.com/sandi.net/ccte/ccte-department/andreanna-murphy?authuser=0"
                      title="Learn more about Andreanna Murphy"
                      target="_blank"
                    >
                      Andreanna Murphy
                    </a>
                  </strong>
                  <br />
                  Head Counselor in the Office of College, Career and Technical
                  Education
                  <br />
                  at{" "}
                  <a
                    href="https://www.sandiegounified.org/"
                    title="Learn more about San Diego Unified School District"
                    target="_blank"
                  >
                    San Diego Unified School District
                  </a>
                </p>
              </div>
              <h3>Resources</h3>
              <div className="edu-22__speakers-list resources">
                <p>
                  <h5 style={{ margin: 0 }}>
                    <a href={pdf} target="_blank">
                      Academic Advantage Presentation Slides
                    </a>
                  </h5>
                  <br />
                  <ul>
                    <li>
                      <a
                        href="https://www.concorde.edu/campus/san-diego-california"
                        target="_blank"
                      >
                        Concorde Career College
                      </a>
                    </li>
                    <li>
                      <a href="https://www.fhcsd.org/" target="_blank">
                        Family Health Centers of San Diego
                      </a>
                    </li>
                    <li>
                      <a href="https://www.sdcity.edu/" target="_blank">
                        San Diego City College
                      </a>
                    </li>
                    <li>
                      <a href="https://sdcce.edu/" target="_blank">
                        San Diego College of Continuing Education
                      </a>
                    </li>
                  </ul>
                </p>

                <p>
                  <ul>
                    <li>
                      <a href="https://www.sdmesa.edu/" target="_blank">
                        San Diego Mesa College
                      </a>
                    </li>
                    <li>
                      <a href="https://careers.sharp.com/" target="_blank">
                        Sharp HealthCare
                      </a>
                    </li>
                    <li>
                      <a href="https://www.swccd.edu/" target="_blank">
                        Southwestern College
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.thebrainobservatory.org/"
                        target="_blank"
                      >
                        The Brain Observatory
                      </a>
                    </li>
                    <li>
                      <a href="https://ucsd.edu/" target="_blank">
                        UC San Diego
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://hmp3.ucsd.edu/programs.html#HOP"
                        target="_blank"
                      >
                        UC San Diego &#8211; Health Outreach Program (HOP)
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="edu-22__form edu-22__section" id="edu-22-form">
            <div className="edu-22__container bottom">
              {/* <h3>Scholarship Application &#8211; Apply by December 15</h3>
              <iframe
                class="seamlessdocsEmbededIframe"
                src="https://secureform.seamlessdocs.com/f/m8d55ho4s5ok?embedded=true"
                width="100%"
                height="2000px"
                frameborder="0"
                allowtransparency="true"
                sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
              ></iframe> */}

              <p>
                <i>
                  This event is organized by The Oral Surgery & Dental Implant
                  Specialists of San Diego in partnership with the College,
                  Career & Technical Education Department from the San Diego
                  Unified School District.
                </i>
              </p>
            </div>
            <ImageSlider images={images} useDots useArrows adaptiveHeight />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default academicadvantage
